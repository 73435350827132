<template>

	<div class="wrap p-guide scroll">
		<!-- header -->
		<header class="header-step">
			<div class="head-left">
				<button type="button" class="btn-back" @click="$router.back()"><span class="blind">이전 페이지</span></button>
			</div>
			<div class="head-center">
				<div class="head-tit">
					<span class="page-tit">Learning Guide</span>
				</div>
			</div>
			<div class="head-right"></div>
		</header>
		<!-- container -->
		<div class="container">
			<div class="content">
				<div class="guide-wrap tabs">
					<!-- tab -->
					<ul class="tab-nav">
						<li class="active"><button type="button">학습하기</button></li>
						<li><button type="button">Book Test</button></li>
						<li><button type="button">Fun Phonics</button></li>
						<li><button type="button">칭찬 스티커</button></li>
						<li><button type="button">학습 진행률</button></li>
					</ul>
					<!-- 학습하기 -->
					<div class="tab-cont cont01 active">
						<div class="swiper">
							<ul class="swiper-wrapper">
								<li class="swiper-slide slide01">
									<img src="/assets/images/learning/img_guide_tab01_01.png" alt="">
								</li>
								<li class="swiper-slide slide02">
									<img src="/assets/images/learning/img_guide_tab01_02.png" alt="">
								</li>
								<li class="swiper-slide slide03">
									<img src="/assets/images/learning/img_guide_tab01_03.png" alt="">
								</li>
								<li class="swiper-slide slide04">
									<img src="/assets/images/learning/img_guide_tab01_04.png" alt="">
								</li>
							</ul>
							<div class="swiper-pagination"></div>
						</div>
					</div>
					<!-- Book Test -->
					<div class="tab-cont cont02">
						<img src="/assets/images/learning/img_guide_tab02.png" alt="">
					</div>
					<!-- Fun Phonics -->
					<div class="tab-cont cont03">
						<img src="/assets/images/learning/img_guide_tab03.png" alt="">
					</div>
					<!-- 칭찬 스티커 -->
					<div class="tab-cont cont04">
						<img src="/assets/images/learning/img_guide_tab04.png" alt="">
					</div>
					<!-- 학습 진행률 -->
					<div class="tab-cont cont05">
						<img src="/assets/images/learning/img_guide_tab05.png" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import $ from 'jquery'
import Swiper from "../../public/assets/js/swiper-bundle.min";


export default {
	name: "guide",
	data () {

		const swiper = () => {
			new Swiper(".p-guide .swiper", {
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
			})
		};

		return {
			swiper
		}
	},
	created() {
	},
	mounted() {

		// tabs
		$(".tab-nav li").on("click", function () {
			var nav = $(this);
			var idx = nav.index();
			var wrap = nav.closest(".tabs");
			wrap.find(".tab-cont").removeClass("active");
			wrap.find(".tab-nav li").removeClass("active");
			wrap.find(".tab-cont:eq(" + idx + ")").addClass("active");
			nav.addClass("active");
		});

		this.swiper();
	},
}
</script>

<style scoped>

</style>